import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import getUrlParameterByName from "./utils";

const app = getUrlParameterByName("app", "upload")

function importApp() {
    if (app === "upload") {
        return import("./UploadApp")
    } else {
        return import("./PlayerApp")
    }
}

importApp().then(({default: Environment}) => {
    ReactDOM.render(
        <React.StrictMode>
            <Environment/>
        </React.StrictMode>
        ,
        document.getElementById("root")
    );
})
